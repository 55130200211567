import AppManagerClient from '../../api/AppManagerClient';
import React, { useEffect } from 'react';
import { ApplicationDetailsView } from './ApplicationDetailsView';
import { IApplication, IODataQuery } from '../../api/models';
import { AppType } from '../../constants/AppType';

export const ApplicationDetails = (props: any) => {
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const applicationName = props.applicationName;
    const applicationType = props.applicationType;

    const [app, setApp]: [IApplication, Function] = React.useState({
        id: 0,
        name: '',
        displayName: '',
        type: '',
        description: '',
    });

    const getApp = async (appType: string, appName: string) => {
        try {
            const criteria: IODataQuery = {
                filter: `type eq '${appType}' and name eq '${appName}'`,
                top: 1,
                select: null,
                orderBy: null,
                expand: null,
            };

            const appResult = await AppManagerClient.applications.get(criteria);
            if (appResult && appResult.length !== 0) {
                setApp(appResult[0]);
            } else if (appType === AppType.ScheduledTask) {
                //ToDo tpiech: REMOVE THIS ELSE IF
                const mockedApp = {
                    id: 0,
                    name: appName,
                    displayName: appName + '_TEST',
                    type: appType,
                    description: 'TESTDESC',
                };
                setApp(mockedApp);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getApp(applicationType, applicationName);
    }, [applicationType, applicationName]);

    return <ApplicationDetailsView application={app} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={!app} applicationName={applicationName} />;
};
