import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import {
    faClock,
    faCode,
    faCogs,
    faDownload,
    faFlaskVial,
    faGlobe,
    faHome,
    faScroll,
    faServer,
    faSignal,
    faSitemap,
    faWindowMaximize,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EnvironmentBanner,
    LayoutContext,
    SiteNavExpandCollapseSection,
    SiteNavSection,
    StandardLayout,
    TopNav,
    TopNavDropdownMenuSignoutAnchor,
    TopNavQuadSiteLogo,
    TopNavUserMenu,
    useDefaultLayoutContext,
} from '@quad/bootstrap-react';
import { DefaultSiteNav, SiteNavLinkItem } from '@quad/bootstrap-react.react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import appConfig from '../../appConfig';
import ApplicationSearch from '../../components/Applications/ApplicationSearch';
import ServersView from '../../components/Servers/ServersView';
import Redirect from '../../components/Shared/Redirect';
import { AppType } from '../../constants/AppType';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useCurrentUserInfo from '../../hooks/useCurrentUserInfo';
import { Permission } from '../../permissions/permission';
import { getRootPermissions } from '../../store/userPrincipal/actions';
import userPrincipalSelectors from '../../store/userPrincipal/selectors';
import { AgentInstallation } from './AgentInstallation';
import { AppPoolDetails } from './AppPoolDetails';
import { VirtualAppDetails } from './VirtualAppDetails';
import AppPools from './AppPools';
import { AuthorizationWrapper } from './AuthorizationWrapper';
import Home from './Home';
import { ServerDetails } from './ServerDetails';
import Signout from './Signout';
import { WebSites } from './WebSites';
import { VirtualApps } from './VirtualApps';
import { WindowsServiceDetails } from './WindowsServiceDetails';
import WindowsServices from './WindowsServices';
import { UserWindowsServicesDashboard } from './WindowsServicesDashboard';
import { WebSiteDetails } from './WebSiteDetails';
import { ScheduledTasks } from './ScheduledTasks';
import { ScheduledTaskDetails } from './ScheduledTaskDetails';
import { ApplicationStatus } from './ApplicationStatus';
import { Swagger } from './Swagger';
import { CertificateDetails } from './CertificateDetails';

interface PageConfig {
    key: string | number;
    permission: Permission;
    siteNavItem?: React.ReactNode;
}

const SiteNavItemIcon = ({ icon }: { icon: IconDefinition }) => <FontAwesomeIcon style={{ width: '1.5rem', height: '1.5rem' }} icon={icon} />;

const menus: PageConfig[] = [
    {
        key: 'Home',
        permission: Permission.AtLeastOne,
        siteNavItem: (
            <>
                <SiteNavLinkItem to="/home" icon={<SiteNavItemIcon icon={faHome} />} label="Home" />
                <hr></hr>
            </>
        ),
    },
    {
        key: 'AppPools',
        permission: Permission.Root_AppPools,
        siteNavItem: (
            <SiteNavLinkItem to={'/applications/' + AppType.AppPool} path={'/applications/' + AppType.AppPool + '/*'} icon={<SiteNavItemIcon icon={faGlobe} />} label="App Pools" />
        ),
    },
    {
        key: 'ScheduledTasks',
        permission: Permission.Root_ScheduledTasks,
        siteNavItem: (
            <SiteNavLinkItem
                to={'/applications/' + AppType.ScheduledTask}
                path={'/applications/' + AppType.ScheduledTask + '/*'}
                icon={<SiteNavItemIcon icon={faClock} />}
                label="Scheduled Tasks"
            />
        ),
    },
    {
        key: 'VirtualApps',
        permission: Permission.Root_VirtualApps,
        siteNavItem: (
            <SiteNavLinkItem
                to={'/applications/' + AppType.VirtualApp}
                path={'/applications/' + AppType.VirtualApp + '/*'}
                icon={<SiteNavItemIcon icon={faWindowMaximize} />}
                label="Virtual Apps"
            />
        ),
    },
    {
        key: 'WebSites',
        permission: Permission.Root_WebSites,
        siteNavItem: (
            <SiteNavLinkItem
                to={'/applications/' + AppType.WebSite}
                path={'/applications/' + AppType.WebSite + '/*'}
                icon={<SiteNavItemIcon icon={faSitemap} />}
                label="Web Sites"
            />
        ),
    },
    {
        key: 'WindowsServices',
        permission: Permission.Root_WindowsServices,
        siteNavItem: (
            <>
                <SiteNavLinkItem
                    to={'/applications/' + AppType.WindowsService}
                    path={'/applications/' + AppType.WindowsService + '/*'}
                    icon={<SiteNavItemIcon icon={faCogs} />}
                    label="Windows Services"
                />
                <hr></hr>
            </>
        ),
    },
    {
        key: 'Servers',
        permission: Permission.Root_Servers,
        siteNavItem: (
            <>
                <SiteNavLinkItem to="/servers" path="/servers/*" icon={<SiteNavItemIcon icon={faServer} />} label="Servers" />
                <hr></hr>
            </>
        ),
    },
    {
        key: 'Certificates',
        permission: Permission.Root_Certificates,
        siteNavItem: (
            <>
                <SiteNavLinkItem to={'/certificates'} path={'/certificates/*'} icon={<SiteNavItemIcon icon={faScroll} />} label="Certificates" />
                <hr></hr>
            </>
        ),
    },
    {
        key: 'ApplicationStatus',
        permission: Permission.Root_Applications,
        siteNavItem: <SiteNavLinkItem to="/dashboards/appstatus" path="/dashboards/appstatus" icon={<SiteNavItemIcon icon={faSignal} />} label="Application Status" />,
    },
    {
        key: 'WindowsServiceDashboard',
        permission: Permission.Root_WindowsServices,
        siteNavItem: (
            <>
                <SiteNavLinkItem
                    to={`/dashboards/${AppType.WindowsService}`}
                    path={`/dashboards/${AppType.WindowsService}`}
                    icon={<SiteNavItemIcon icon={faFlaskVial} />}
                    label="Service Dashboard"
                />
                <hr></hr>
            </>
        ),
    },
    {
        key: 'Swagger',
        permission: Permission.AtLeastOne,
        siteNavItem: (
            <>
                <SiteNavLinkItem to={'/swagger/'} path={'swagger'} icon={<SiteNavItemIcon icon={faCode} />} label="Swagger" />
            </>
        ),
    },
    {
        key: 'AgentInstallation',
        permission: Permission.Root_InstallAgent,
        siteNavItem: (
            <>
                <hr></hr>
                <SiteNavLinkItem to="/admin/agent-installation" icon={<SiteNavItemIcon icon={faDownload} />} label="Agent Installation" />
            </>
        ),
    },
];

function App() {
    const dispatch = useAppDispatch();
    const layoutContext = useDefaultLayoutContext();
    const currentUserInfo = useCurrentUserInfo();
    const [isLoading, setIsLoading] = useState(true);
    const hasPermissionTest = useAppSelector(userPrincipalSelectors.selectHasPermissionTest);

    useEffect(() => {
        if (currentUserInfo?.isAuthenticated) {
            dispatch(getRootPermissions()).then(() => setIsLoading(false));
        }
    }, [dispatch, currentUserInfo?.isAuthenticated]);

    const topNav = (
        <TopNav
            siteLogo={<TopNavQuadSiteLogo />}
            userMenu={<TopNavUserMenu displayName={currentUserInfo?.displayName ?? ''} signout={<TopNavDropdownMenuSignoutAnchor anchorProps={{ href: 'signout' }} />} />}
        />
    );

    const filteredMenuConfigs = useMemo(() => {
        return menus.filter((i) => hasPermissionTest(i.permission));
    }, [hasPermissionTest]);

    const siteNavItems = filteredMenuConfigs.map((pc) => <React.Fragment key={pc.key}>{pc.siteNavItem}</React.Fragment>);

    const siteNav = (
        <DefaultSiteNav>
            <SiteNavSection>{siteNavItems}</SiteNavSection>
            <SiteNavExpandCollapseSection />
        </DefaultSiteNav>
    );

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <LayoutContext.Provider value={layoutContext}>
                <EnvironmentBanner environment={appConfig.appEnvironment} />
                <StandardLayout topNav={topNav} siteNav={siteNav}>
                    <AuthorizationWrapper permission={Permission.AtLeastOne} isLoading={isLoading} showAccessDenied={true}>
                        <Routes>
                            <Route path="app/*" element={<RemoveApp />} />
                            <Route path={'applications/*'}>
                                <Route path={AppType.AppPool + '/*'}>
                                    <Route index element={<AppPools />} />
                                    <Route path=":name" element={<AppPoolDetails />} />
                                </Route>
                                <Route path={AppType.ScheduledTask + '/*'}>
                                    <Route index element={<ScheduledTasks />} />
                                    <Route path=":name" element={<ScheduledTaskDetails />} />
                                </Route>
                                <Route path={AppType.WebSite + '/*'}>
                                    <Route index element={<WebSites />} />
                                    <Route path=":name" element={<WebSiteDetails />} />
                                </Route>
                                <Route path={AppType.WindowsService + '/*'}>
                                    <Route index element={<WindowsServices />} />
                                    <Route path=":name" element={<WindowsServiceDetails />} />
                                </Route>
                                <Route path={AppType.VirtualApp + '/*'}>
                                    <Route index element={<VirtualApps />} />
                                    <Route path=":name" element={<VirtualAppDetails />} />
                                </Route>
                            </Route>
                            <Route path="application-search/:name" element={<ApplicationSearch />} />
                            <Route path="servers/*">
                                <Route index element={<ServersView />} />
                                <Route path=":name" element={<ServerDetails />} />
                            </Route>
                            <Route path="certificates/*">
                                <Route index element={<ServersView />} />
                                <Route path=":thumbprint" element={<CertificateDetails />} />
                            </Route>
                            <Route path="home" element={<Home />} />
                            <Route path="admin/*">
                                <Route path="agent-installation" element={<AgentInstallation />} />
                            </Route>
                            <Route path="dashboards/*">
                                <Route path="appstatus" element={<ApplicationStatus />} />
                                <Route path={AppType.WindowsService} element={<UserWindowsServicesDashboard />} />
                            </Route>
                            <Route path="swagger">
                                <Route index element={<Swagger />} />
                            </Route>
                            <Route path="signout" element={<Signout />} />
                            <Route path="*" element={<Redirect to="/home" />} />
                        </Routes>
                    </AuthorizationWrapper>
                </StandardLayout>
            </LayoutContext.Provider>
        </MsalAuthenticationTemplate>
    );
}

export default App;

// I'm going to guess this had something to do with the move of splitting up the hosting of the app / api, getting their own domain.
// Can we get rid of it now?
const RemoveApp = () => {
    const { pathname } = useLocation();
    const newPath = pathname.substring('/app'.length);

    return <Redirect to={newPath} />;
};
