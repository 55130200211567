import { Col, Row } from 'react-bootstrap';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';
import { IServer } from '../../api/models';
import { AllowedSystemLocationsView } from '../AllowedSystemLocations/AllowedSystemLocationsView';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { ServerLastDiscoveredTimestamp } from '../Shared/ServerLastDiscoveredTimestamp';

export interface ServerInfoViewProps {
    userPrincipal: UserPrincipal;
    serverName: string;
    server: IServer;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
}
export const ServerInfoView = (props: ServerInfoViewProps) => {
    return (
        <>
            <ApiDataWrapper objectType="Details" objectName={props.serverName} isLoading={props.isLoading} isDataNotFound={props.isDataNotFound} errorMessage={props.errorMessage}>
                <ServerInfoDataView userPrincipal={props.userPrincipal} server={props.server} />
            </ApiDataWrapper>
        </>
    );
};

export interface ServerInfoDataViewProps {
    userPrincipal: UserPrincipal;
    server: IServer;
}
export const ServerInfoDataView = (props: ServerInfoDataViewProps) => {
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={3}>Name:</Col>
                        <Col>{props.server.name}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>Environment:</Col>
                        <Col>{props.server.environment}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>Last Discovery:</Col>
                        <Col>
                            <ServerLastDiscoveredTimestamp lastDiscoveredTimestamp={props.server.lastDiscoveredTimestamp} />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <AllowedSystemLocationsView userPrincipal={props.userPrincipal} server={props.server} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
