import { Table } from 'react-bootstrap';
import { ICertificateAccessRight } from '../../api/models';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { CertificateAccessRightTableRow } from './CertificateAccessRightTableRow';

interface CertificateAccessRightTableProps {
    accessRights: ICertificateAccessRight[];
}
export const CertificateAccessRightTable = ({ accessRights }: CertificateAccessRightTableProps) => {
    return (
        <Table striped size="sm">
            <thead>
                <tr>
                    <th scope="col" className="col-4">
                        Identity
                    </th>
                    <th scope="col" className="col-1">
                        Rights
                    </th>
                    <th scope="col" className="col-4">
                        Type
                    </th>
                </tr>
            </thead>
            <tbody>
                {accessRights !== null && accessRights.length > 0 ? (
                    accessRights.map(function (accessRight: ICertificateAccessRight) {
                        return <CertificateAccessRightTableRow accessRight={accessRight} key={accessRight.id} />;
                    })
                ) : (
                    <EmptyDataRow colSpan={3} />
                )}
            </tbody>
        </Table>
    );
};
