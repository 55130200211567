import { Table } from 'react-bootstrap';
import { IEventLog } from '../../api/models';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { StringUtils } from '../../shared/StringUtils';
import { ITextWithIcon, TextWithIcon } from '../UIExtentions/Text';
import { DateDisplay } from '../Shared/DateDisplay';

interface EventLogViewProps {
    objectName: string;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
    eventList: IEventLog[];
}
export const EventLogView = (props: EventLogViewProps) => {
    return (
        <AccordionWrapper
            isDefaultOpen={true}
            headerIcon="fa-clock-o"
            headerText="Recent Events"
            objectName={props.objectName}
            isLoading={props.isLoading}
            isDataNotFound={props.isDataNotFound}
            errorMessage={props.errorMessage}
        >
            <Table striped className="table-sm table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Operation</th>
                        <th scope="col">Status</th>
                        <th scope="col">Server</th>
                        <th scope="col">User</th>
                        <th scope="col">Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {props.eventList !== undefined && props.eventList !== null && props.eventList.length > 0 ? (
                        props.eventList.map(function (d) {
                            return <EventLogRow event={d} key={d.createdTimestamp} />;
                        })
                    ) : (
                        <EmptyDataRow colSpan={6} />
                    )}
                </tbody>
            </Table>
        </AccordionWrapper>
    );
};

export const EventLogRow = (props: any) => {
    const event = props.event as IEventLog;
    const date = new Date(event.createdTimestamp + 'Z');

    return (
        <tr className={getRowColor(event.type)}>
            <td>
                <DateDisplay date={date} />
            </td>
            <td>
                <TextWithIcon {...GetOperationProps(event.operation)} />
            </td>
            <td>
                <TextWithIcon {...GetEventTypeProps(event.type)} />
            </td>
            <td>{event.targetServer}</td>
            <td>
                <User user={event.user} />
            </td>
            <td>
                <Summary text={event.summary} />
            </td>
        </tr>
    );
};

export const GetEventTypeProps = (eventType: string): ITextWithIcon => {
    const event = StringUtils.substringAfterSeparator('.', eventType);
    switch (event) {
        case 'triggered':
            return {
                text: 'Triggered',
                icon: 'fa fa-clock-o',
            };

        case 'created':
            return {
                text: 'Created',
                icon: 'fa fa-check-circle',
            };
        case 'succeeded':
            return {
                text: 'Success',
                icon: 'fa fa-check-circle',
            };
        case 'failed':
            return {
                text: 'Failed',
                icon: 'fa fa-exclamation-circle',
            };
        case 'added':
            return {
                text: 'Added',
                icon: 'fa fa-plus',
            };
        case 'removed':
            return {
                text: 'Removed',
                icon: 'fa fa-minus',
            };
        case 'updated':
            return {
                text: 'Updated',
                icon: 'fa fa-circle',
            };
        case 'posted':
            return {
                text: 'Posted',
                icon: 'fa fa-share',
            };
        default:
            return {
                text: event,
                icon: 'fa fa-question-circle',
            };
    }
};

export const User = (props: any) => {
    const user = props.user;

    return (
        <>
            <div className="event-log-user" title={user.DisplayName}>
                {user.DisplayName}
            </div>
        </>
    );
};

export const GetOperationProps = (operationType: string): ITextWithIcon => {
    const operation = StringUtils.substringAfterSeparator('.', operationType);
    switch (operation) {
        case 'start':
            return {
                text: 'Start',
                icon: 'fa fa-play-circle',
            };
        case 'stop':
            return {
                text: 'Stop',
                icon: 'fa fa-stop-circle',
            };
        case 'restart':
            return {
                text: 'Restart',
                icon: 'fa fa-step-forward',
            };
        case 'kill':
            return {
                text: 'Kill',
                icon: 'fa fa-bomb',
            };
        case 'discover':
            return {
                text: 'Discover',
                icon: 'fa fa-eye',
            };
        case 'change-startup-type':
        case 'changestarttype':
            return {
                text: 'Startup change',
                icon: 'fa fa-plug',
            };
        case 'refresh':
            return {
                text: 'Refresh',
                icon: 'fa fa-refresh',
            };
        case 'recycle':
            return {
                text: 'Recycle',
                icon: 'fa fa-recycle',
            };
        case 'permissions-update':
            return {
                text: 'Permission',
                icon: 'fa fa-user',
            };
        case 'delete-install-info':
            return {
                text: 'Install Info Delete',
                icon: 'fa fa-info',
            };
        case 'update-install-info':
            return {
                text: 'Install Info Update',
                icon: 'fa fa-info',
            };
        case 'insert-install-info':
            return {
                text: 'Install Info Insert',
                icon: 'fa fa-info',
            };
        case 'install':
            return {
                text: 'Install',
                icon: 'fa fa-plus',
            };
        case 'uninstall':
            return {
                text: 'Uninstall',
                icon: 'fa fa-minus',
            };
        case 'create':
            return {
                text: 'Created',
                icon: 'fa fa-plus',
            };
        case 'delete':
            return {
                text: 'Deleted',
                icon: 'fa fa-minus',
            };
        case 'permissions-delete':
            return {
                text: 'Permission',
                icon: 'fa fa-user',
            };
        case 'update':
            return {
                text: 'Update',
                icon: 'fa fa-wrench',
            };
        case 'cleanup':
            return {
                text: 'Cleanup',
                icon: 'fa fa-trash',
            };
        case 'enable':
            return { text: 'Enabled', icon: 'fa fa-square-check' };
        case 'disable':
            return { text: 'Disabled', icon: 'fa fa-ban' };
        case 'ping':
            return {
                text: 'Ping',
                icon: 'fa fa-table-tennis-paddle-ball',
            };
        default:
            return {
                text: operation,
            };
    }
};

export const Summary = (props: any) => {
    return (
        <>
            <div className="event-log-summary" title={props.text}>
                {props.text}
            </div>
        </>
    );
};

function getRowColor(text: string) {
    const operationType = StringUtils.substringAfterSeparator('.', text);
    switch (operationType) {
        case 'succeeded':
            return 'table-success';
        case 'failed':
            return 'table-danger';
        default:
            return '';
    }
}
