import AppManagerClient from '../../api/AppManagerClient';
import { useState, useEffect } from 'react';
import { CertificateInfoView } from './CertificateInfoView';
import { IODataQuery } from '../../api/models';

export const CertificateInfo = (props: any) => {
    const [certificate, setCertificate] = useState({} as any);
    const [isLoading, setLoadingStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const thumbprint = props.thumbprint;

    const getCertificate = async (thumbprint: string) => {
        const criteria: IODataQuery = {
            filter: `thumbprint eq '${thumbprint}'`,
            expand: null,
            select: null,
            top: null,
            orderBy: null,
        };

        try {
            const certificateResults = await AppManagerClient.getCertificates(criteria);
            if (certificateResults !== undefined && certificateResults.length > 0) {
                setCertificate(certificateResults[0]);
            }
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getCertificate(thumbprint);
    }, [thumbprint]);

    useEffect(() => {
        props.setFriendlyName(certificate.friendlyName);
    }, [props, certificate]);

    return <CertificateInfoView certificate={certificate} isLoading={isLoading} errorMessage={errorMessage} isDataNotFound={certificate === undefined} />;
};
