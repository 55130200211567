import { Row, Col, ToastContainer, Toast } from 'react-bootstrap';
import { ApiDataWrapper } from '../UIExtentions/ApiDataWrapper';
import { DateDisplay } from '../Shared/DateDisplay';
import { ActionButton } from '../UIExtentions/Buttons';
import { useState } from 'react';
import { dateToLocal } from '../../helpers/DateHelpers';

export const CertificateInfoView = (props: any) => {
    return (
        <>
            <ApiDataWrapper
                objectType="Certificate Info"
                objectName={props.thumbprint}
                isLoading={props.isLoading}
                isDataNotFound={props.isDataNotFound}
                errorMessage={props.errorMessage}
            >
                <CertificateDetails certificate={props.certificate} />
            </ApiDataWrapper>
        </>
    );
};

function constructValidToDisplay(validTo: string) {
    const today = new Date();
    const certExpirationDate = new Date(validTo);
    const formattedDate = dateToLocal(certExpirationDate);
    if (today < certExpirationDate) {
        return (
            <>
                <p className="text-success">
                    <span>
                        <span title={`${certExpirationDate}`}>{`${formattedDate}`}</span>
                        &nbsp;
                        <i className="fa fa-check-circle" title="Certificate valid"></i>
                    </span>
                </p>
            </>
        );
    } else {
        return (
            <>
                <p className="text-danger">
                    <span>
                        <span title={`${certExpirationDate}`}>{`${formattedDate}`}</span>
                        &nbsp;
                        <i className="fa fa-exclamation-circle" title="Certificate expired!"></i>
                    </span>
                </p>
            </>
        );
    }
}

export function CertificateDetails(props: any) {
    const certificate = props.certificate;

    const [showCopyToast, setShowCopyToast]: [boolean, Function] = useState(false);

    function copyThumbprint() {
        navigator.clipboard.writeText(certificate.thumbprint);
        setShowCopyToast(true);
    }

    return (
        <>
            <Row>
                <Col sm={2}>Thumbprint:</Col>
                <Col>
                    {certificate.thumbprint} <ActionButton icon="fa-copy" variant="btn-dark-blue" title="Copy" onClick={() => copyThumbprint()} />
                    <ToastContainer style={{ zIndex: 999 }} position="middle-center">
                        <Toast onClose={() => setShowCopyToast(false)} show={showCopyToast} delay={2000} autohide bg="white">
                            <Toast.Header>
                                <strong className="me-auto">Copied!</strong>
                            </Toast.Header>
                            <Toast.Body>Thumbprint copied successfully.</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>Friendly Name:</Col>
                <Col>{certificate.friendlyName}</Col>
            </Row>
            <Row>
                <Col sm={2}>Subject:</Col>
                <Col>{certificate.subject}</Col>
            </Row>
            <Row>
                <Col sm={2}>Alternative Subject:</Col>
                <Col>{certificate.subjectAlternativeName}</Col>
            </Row>
            <Row>
                <Col sm={2}>Issuer:</Col>
                <Col>{certificate.issuer}</Col>
            </Row>
            <Row>
                <Col sm={2}>Key Usages:</Col>
                <Col>{certificate.keyUsages}</Col>
            </Row>
            <Row>
                <Col sm={2}>Valid From:</Col>
                <Col>
                    <DateDisplay date={new Date(certificate.validFrom)} />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>Valid To:</Col>
                <Col>{constructValidToDisplay(certificate.validTo)}</Col>
            </Row>
        </>
    );
}
