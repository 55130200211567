import { IServerCertificate } from '../../api/models';
import { ServerCertificateLastDiscoveredCell } from './ServerCertificateLastDiscoveredCell';
import { ServerCertificateTableRowActions } from './ServerCertificateTableRowActions';

export interface ServerCertificateTableRowProps {
    serverCertificate: IServerCertificate;
    friendlyName: string;
}

export function ServerCertificateTableRow(props: ServerCertificateTableRowProps) {
    const serverCertificate = props.serverCertificate;
    const friendlyName = props.friendlyName;

    return (
        <tr>
            <td className="details-link">{<ServerCertificateLastDiscoveredCell serverCertificate={serverCertificate} friendlyName={friendlyName} />}</td>
            <td>{serverCertificate.server.environment}</td>
            <td>{serverCertificate.storeLocation}</td>
            <td>{serverCertificate.storeName}</td>
            <td>
                <ServerCertificateTableRowActions serverCertificate={serverCertificate} friendlyName={friendlyName}></ServerCertificateTableRowActions>
            </td>
        </tr>
    );
}
