import AppManagerClient from '../../api/AppManagerClient';
import { IODataQuery, IServerCertificate } from '../../api/models';
import { useEffect, useState } from 'react';
import { ServerCertificateManagementView } from './ServerCertificateManagementView';
import { Stack } from 'react-bootstrap';

export const ServerCertificateManagement = (props: any) => {
    const [serverCertificates, setServerCertificates] = useState(Array<IServerCertificate>());
    const [isLoading, setLoadingStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const thumbprint = props.thumbprint;
    const friendlyName = props.friendlyName;

    const getServers = async (thumbprint: string) => {
        try {
            const criteria: IODataQuery = {
                filter: `thumbprint eq '${thumbprint}'`,
                expand: 'serverCertificates($expand=Server,AccessRights)',
                select: 'thumbprint',
                top: null,
                orderBy: null,
            };
            const certificateResults = await AppManagerClient.getCertificates(criteria);
            const serverCertificateResults = certificateResults[0].serverCertificates;
            setServerCertificates(serverCertificateResults);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setLoadingStatus(false);
        }
    };

    useEffect(() => {
        getServers(thumbprint);
    }, [thumbprint]);

    return (
        <Stack gap={3}>
            <ServerCertificateManagementView
                serverCertificates={serverCertificates}
                isLoading={isLoading}
                errorMessage={errorMessage}
                isDataNotFound={serverCertificates.length === 0}
                friendlyName={friendlyName}
            />
        </Stack>
    );
};
