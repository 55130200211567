export const stringToDate = (dateString: string | undefined): Date | undefined => {
    return dateString ? new Date(dateString + '+0000') : undefined;
};
export const stringToLocal = (dateString: string | undefined): string => {
    const date = stringToDate(dateString);
    return dateToLocal(date);
};
export const dateToLocal = (date: Date | undefined): string => {
    return date ? date.toLocaleString() : '';
};

export const stringToISO = (dateString: string | undefined): string => {
    const date = stringToDate(dateString);
    return dateToISO(date);
};
export const dateToISO = (date: Date | undefined): string => {
    return date ? date.toISOString() : '';
};
