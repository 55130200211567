import { useState } from 'react';
import { IServerCertificate } from '../../api/models';
import { InfoModal } from '../Shared/InfoModal';
import { ActionButton } from '../UIExtentions/Buttons';
import { CertificateAccessRightTable } from './CertificateAccessRightTable';

export interface ServerCertificateTableRowActionsProps {
    serverCertificate: IServerCertificate;
    friendlyName: string;
}

export function ServerCertificateTableRowActions(props: ServerCertificateTableRowActionsProps) {
    const [showInfo, setShowInfo] = useState(false);
    const serverCertificate = props.serverCertificate;
    const friendlyName = props.friendlyName;
    const infoModalTitle = `${friendlyName} - ${serverCertificate.server.name} Access Rights`;

    return (
        <>
            <td>
                <ActionButton icon="fa-universal-access" variant="btn-dark-blue" title="Access Rights" onClick={() => setShowInfo(true)} />
                <InfoModal title={infoModalTitle} show={showInfo} onHide={() => setShowInfo(false)}>
                    <CertificateAccessRightTable accessRights={serverCertificate.accessRights}></CertificateAccessRightTable>
                </InfoModal>
            </td>
        </>
    );
}
