import appConfig from '../appConfig';
import { ScheduledTaskAction } from '../constants/ScheduledTaskAction';
import MsalClientBase from './MsalClientBase';
import {
    IActiveDirectoryGroup,
    IApiRequest,
    IApplication,
    IApplicationPermission,
    IEventLog,
    IInstallInfo,
    IManagingWindowsServiceInstance,
    IManagingAppPoolsInstance,
    IManagingVirtualAppsInstance,
    IName,
    IPostApplicationActionResponse,
    IServerApplication,
    IServer,
    IPermissionRequest,
    IServerPermission,
    IAgent,
    IApplicationPermissionRequest,
    IServerPropertiesUpdateRequest,
    IODataQuery,
    IManagingWebSitesInstance,
    IManagingScheduledTasksInstance,
    IManagingApplicationInstance,
    ICertificate,
} from './models';
import { Dictionary } from 'lodash';

class AppManagerClient extends MsalClientBase {
    constructor() {
        super(appConfig.apiBaseUrl);
    }

    private callApi = async <T>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: any): Promise<T> => {
        const request: IApiRequest = {
            url: path,
            method: method,
            body: body,
        };

        return await this.fetchJson(request);
    };

    private callApiNulable = async (path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: any) => {
        const request: IApiRequest = {
            url: path,
            method: method,
            body: body,
        };

        await this.fetchJsonNullable(request);
    };

    private get = async <T>(path: string, body?: any): Promise<T> => {
        return this.callApi(path, 'GET', body);
    };

    private post = async <T>(path: string, body?: any): Promise<T> => {
        return this.callApi(path, 'POST', body);
    };

    private delete = async (path: string, body?: any) => {
        return this.callApiNulable(path, 'DELETE', body);
    };

    private put = async (path: string, body?: any) => {
        return this.callApiNulable(path, 'PUT', body);
    };

    private getSingleOrUndefined = async <T>(array: T[]): Promise<T | undefined> => {
        if (array.length !== 1) {
            return undefined;
        }
        return array[0];
    };

    private static getODataQueryString = (criteria: IODataQuery | null) => {
        const values = {
            filter: criteria?.filter ? `$filter=${criteria.filter}` : '',
            expand: criteria?.expand ? `&expand=${criteria.expand}` : '',
            select: criteria?.select ? `$select=${criteria.select}` : '',
            top: criteria?.top ? `$top=${criteria.top}` : '',
            orderBy: criteria?.orderBy ? `$select=${criteria.orderBy}` : '',
        };

        let query = '';
        let value: keyof typeof values;
        for (value in values) {
            query += `${values[value]}&`;
        }
        return query;
    };

    getRootPermissions() {
        return this.get<string[]>('permissions/root');
    }

    getToken = async () => {
        const token = await this.getAccessToken();
        return token;
    };

    getAllApplications = async (): Promise<IApplication[]> => {
        const result = await this.get<IApplication[]>('Application');
        return result;
    };

    get applications() {
        return {
            get: (criteria: IODataQuery | null) => {
                const query = AppManagerClient.getODataQueryString(criteria);
                const url = `Application?${query}`;
                return this.get<IApplication[]>(url);
            },
        };
    }

    getUserApplications = async (): Promise<IApplication[]> => {
        const result = await this.get<IApplication[]>('UserApplication');
        return result;
    };

    getUserApplicationsByType = async (type: string): Promise<IApplication[]> => {
        const result = await this.get<IApplication[]>(`UserApplication/Type/${type}`);
        return result;
    };

    getApplicationsByType = async (type: string): Promise<IApplication[]> => {
        const result = await this.get<IApplication[]>(`Application/Type/${type}`);
        return result;
    };

    getServerApplicationsByAppName = async (name: string): Promise<IServerApplication[]> => {
        const result = await this.get<IServerApplication[]>(`ServerApplication/GetByAppName/${name}`);
        return result;
    };

    getInstallInfo = async (): Promise<IInstallInfo> => {
        const result = await this.get<IInstallInfo>('Agent/InstallInfo');
        return result;
    };

    getEvents = async (query: string): Promise<IEventLog[]> => {
        const url = `events?${query}`;
        return await this.get<IEventLog[]>(url);
    };

    getCertificates = async (criteria: IODataQuery | null): Promise<ICertificate[]> => {
        const query = AppManagerClient.getODataQueryString(criteria);
        const url = `certificates?${query}`;
        return await this.get<ICertificate[]>(url);
    };

    getApplicationPermissionsByAppNameAndType = async (name: string, type: string): Promise<IApplicationPermission[]> => {
        const results = await this.get<IApplicationPermission[]>(`Application/${type}/${name}/Permissions`);
        return results;
    };

    getAllUserApplicationPermissions = async (): Promise<IApplicationPermission[]> => {
        const results = await this.get<IApplicationPermission[]>('Application/UserPermission');
        return results;
    };

    getMyManagingApplicationsByType = async (appType: string): Promise<IManagingApplicationInstance[]> => {
        const result = await this.get<IManagingApplicationInstance[]>(`UserApplication/my/${appType}/for-managing`);
        return result;
    };

    postWindowsServiceAction = async (serviceName: string, action: string, serverNames: any, startType?: string): Promise<IPostApplicationActionResponse> => {
        let url = `windows-service/${serviceName}/${action}`;

        if (action === 'changeStartupType') {
            url = url.concat(`?startType=${startType}`);
        }

        const result = await this.post<IPostApplicationActionResponse>(url, { serverFilter: serverNames });
        return result;
    };

    postWindowsServiceInstallAction = async (
        serviceName: string,
        serverNames: string[],
        binPath: string,
        shouldUpdatePassword: boolean,
        runAsUsername?: string,
        runAsPassword?: string
    ): Promise<IPostApplicationActionResponse> => {
        const url = `windows-service/${serviceName}/install`;

        const result = await this.post<IPostApplicationActionResponse>(url, {
            serverFilter: serverNames,
            binPath: binPath,
            runAsUsername: runAsUsername,
            runAsPassword: runAsPassword,
            shouldUpdatePassword: shouldUpdatePassword,
        });
        return result;
    };

    getWindowsServiceForManaging = async (name: string): Promise<IManagingWindowsServiceInstance[]> => {
        const result = await this.get<IManagingWindowsServiceInstance[]>(`windows-service/${name}/getForManaging`);
        return result;
    };

    getWindowsServiceForManagingByServers = async (name: string, serverNames: string[]): Promise<IManagingWindowsServiceInstance[]> => {
        if (serverNames.length === 0) {
            return await this.getWindowsServiceForManaging(name);
        }

        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<IManagingWindowsServiceInstance[]>(`windows-service/${name}/getForManagingByServers?servers=${serverQuery}`);
        return result;
    };

    getWindowsServiceUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`windows-service/${name}/userPermissions`);
        return results;
    };

    postAppPoolsAction = async (serviceName: string, action: string, serverNames: any): Promise<IPostApplicationActionResponse> => {
        const url = `apppool/${serviceName}/${action}`;

        const result = await this.post<IPostApplicationActionResponse>(url, { serverFilter: serverNames });
        return result;
    };

    getAppPoolsForManaging = async (name: string): Promise<IManagingAppPoolsInstance[]> => {
        const result = await this.get<IManagingAppPoolsInstance[]>(`apppool/${name}/getForManaging`);
        return result;
    };

    getAppPoolsForManagingByServers = async (name: string, serverNames: string[]): Promise<IManagingAppPoolsInstance[]> => {
        if (serverNames.length === 0) {
            return await this.getAppPoolsForManaging(name);
        }

        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<IManagingAppPoolsInstance[]>(`apppool/${name}/getForManagingByServers?servers=${serverQuery}`);
        return result;
    };

    getAppPoolsUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`apppool/${name}/userPermissions`);
        return results;
    };

    postWebSitesAction = async (serviceName: string, action: string, serverNames: any): Promise<IPostApplicationActionResponse> => {
        const url = `websites/${serviceName}/${action}`;

        const result = await this.post<IPostApplicationActionResponse>(url, { serverFilter: serverNames });
        return result;
    };

    getWebSitesForManaging = async (name: string): Promise<IManagingWebSitesInstance[]> => {
        const result = await this.get<IManagingWebSitesInstance[]>(`websites/${name}/getForManaging`);
        return result;
    };

    getWebSitesForManagingByServers = async (name: string, serverNames: string[]): Promise<IManagingWebSitesInstance[]> => {
        if (serverNames.length === 0) {
            return await this.getWebSitesForManaging(name);
        }

        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<IManagingWebSitesInstance[]>(`websites/${name}/getForManagingByServers?servers=${serverQuery}`);
        return result;
    };

    getWebSitesUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`websites/${name}/userPermissions`);
        return results;
    };

    getScheduledTasksUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`scheduled-task/${name}/userPermissions`);
        return results;
    };

    getScheduledTasksForManaging = async (name: string): Promise<IManagingScheduledTasksInstance[]> => {
        const result = await this.get<IManagingScheduledTasksInstance[]>(`scheduled-task/${name}/getForManaging`);
        return result;
    };

    getScheduledTasksForManagingByServers = async (name: string, serverNames: string[]): Promise<IManagingScheduledTasksInstance[]> => {
        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<IManagingScheduledTasksInstance[]>(`scheduled-task/${name}/getForManagingByServers?servers=${serverQuery}`);
        return result;
    };

    postScheduledTasksAction = async (scheduledTaskName: string, action: ScheduledTaskAction, serverNames: any): Promise<IPostApplicationActionResponse> => {
        const url = `scheduled-task/${scheduledTaskName}/${action}`;
        const result = await this.post<IPostApplicationActionResponse>(url, { serverFilter: serverNames });
        return result;
    };

    postVirtualAppsAction = async (name: string, action: string, serverNames: any, startType?: string): Promise<IPostApplicationActionResponse> => {
        const url = `virtualapp/${name}/${action}`;
        const result = await this.post<IPostApplicationActionResponse>(url, { serverFilter: serverNames });
        return result;
    };

    getVirtualAppsForManaging = async (name: string): Promise<IManagingVirtualAppsInstance[]> => {
        const result = await this.get<IManagingVirtualAppsInstance[]>(`virtualapp/${name}/getForManaging`);
        return result;
    };

    getVirtualAppsForManagingByServers = async (name: string, serverNames: string[]): Promise<IManagingVirtualAppsInstance[]> => {
        if (serverNames.length === 0) {
            return await this.getVirtualAppsForManaging(name);
        }

        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<IManagingVirtualAppsInstance[]>(`virtualapp/${name}/getForManagingByServers?servers=${serverQuery}`);
        return result;
    };

    getVirtualAppsUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`virtualapp/${name}/userPermissions`);
        return results;
    };

    postApplicationPermission = async (permissionRequest: IApplicationPermissionRequest) => {
        const url = `Application/${permissionRequest.type}/${permissionRequest.name}/Permission`;

        await this.post<any>(url, { activeDirectoryGroupSecurityId: permissionRequest.groupSecurityId, role: permissionRequest.role });
    };

    deleteApplicationPermission = async (permissionRequest: IApplicationPermissionRequest) => {
        const url = `Application/${permissionRequest.type}/${permissionRequest.name}/role/${permissionRequest.role}/group/${permissionRequest.groupSecurityId}`;

        await this.delete(url);
    };

    putApplicationPermission = async (permissionRequest: IApplicationPermissionRequest, currentRole: string) => {
        const url = `application/${permissionRequest.type}/${permissionRequest.name}/permission/${permissionRequest.groupSecurityId}/role`;

        await this.put(url, { currentRole: currentRole, newRole: permissionRequest.role });
    };

    getPermissionGroups = async (searchTerm: string): Promise<IActiveDirectoryGroup[]> => {
        const url = `permission-groups/lookup?term=${searchTerm}`;
        const result = await this.get<IActiveDirectoryGroup[]>(url);
        return result;
    };

    getApplicationUserPermissionsForServers = async (applicationType: string, serverNames: string[]): Promise<Dictionary<Array<string>>> => {
        if (serverNames.length === 0) {
            return {};
        }

        const serverQuery = serverNames.join('&servers=');
        const result = await this.get<Dictionary<Array<string>>>(`permissions/userPermissions/${applicationType}/forServers?servers=${serverQuery}`);
        return result;
    };

    getAllServerNames = async (): Promise<IName[]> => {
        const url = 'Servers?$select=name&';
        return await this.getAllOdata(url, 250);
    };

    getServerByName = async (name: string): Promise<IServer | undefined> => {
        const url = `Servers?$filter=name eq '${name}'`;
        const result = await this.get<IServer[]>(url);
        return this.getSingleOrUndefined(result);
    };

    getAllServers = async (): Promise<IServer[]> => {
        const url = 'Servers?';
        return await this.getAllOdata(url, 250);
    };

    putServerProperties = async (serverName: string, request: IServerPropertiesUpdateRequest) => {
        const url = `Servers/${serverName}/properties`;

        const result = await this.put(url, request);
        return result;
    };

    getUserServers = async (): Promise<IServer[]> => {
        const url = 'UserServer?';
        return await this.getAllOdata(url, 250);
    };

    getAllOdata = async <T>(url: string, numberOfRecordsPerCall: number): Promise<T[]> => {
        const top = numberOfRecordsPerCall;
        let skip = 0;

        let result = Array<T>();
        let partialResult = Array<T>();

        do {
            const oDataUrl = `${url}$top=${top}&$skip=${skip}`;
            partialResult = await this.get<T[]>(oDataUrl);

            result = [...result, ...partialResult];
            skip += top;
        } while (partialResult.length === top);
        return result;
    };

    createApplication = async (name: string, displayName: string, description: string, type: string, ownerSid: string) => {
        const url = 'Application';
        const data = {
            name: name,
            displayName: displayName,
            description: description,
            type: type,
            ownerSid: ownerSid,
        };
        await this.put(url, data);
    };

    deleteApplication = async (name: string) => {
        const url = `Application?name=${name}`;
        await this.delete(url);
    };

    getApplicationUserPermissions = async (name: string, type: string): Promise<string[]> => {
        const results = await this.get<string[]>(`permissions/userPermissions/application/${type}/${name}`);
        return results;
    };

    getServerUserPermissions = async (name: string): Promise<string[]> => {
        const results = await this.get<string[]>(`permissions/userPermissions/server/${name}`);
        return results;
    };

    getServerPermissionsByServerName = async (name: string): Promise<IServerPermission[]> => {
        const results = await this.get<IServerPermission[]>(`Server/${name}/Permissions`);
        return results;
    };

    postServerPermission = async (permissionRequest: IPermissionRequest) => {
        const url = `Server/${permissionRequest.name}/Permission`;

        await this.post<any>(url, { groupSecurityId: permissionRequest.groupSecurityId, role: permissionRequest.role });
    };

    deleteServerPermission = async (permissionRequest: IPermissionRequest) => {
        const url = `Server/${permissionRequest.name}/role/${permissionRequest.role}/group/${permissionRequest.groupSecurityId}`;

        await this.delete(url);
    };

    putServerPermission = async (permissionRequest: IPermissionRequest, currentRole: string) => {
        const url = `Server/${permissionRequest.name}/permission/${permissionRequest.groupSecurityId}/role`;

        await this.put(url, { currentRole: currentRole, newRole: permissionRequest.role });
    };

    getAgentsByServerName = async (serverName: string): Promise<IAgent[]> => {
        const url = `Agents?$filter=serverName eq '${serverName}'&`;
        return await this.getAllOdata(url, 250);
    };
    pingAgent = async (serverName: string, version: string): Promise<string> => {
        const url = `Agents/${serverName}/ping?agentVersion=${version}`;
        return await this.post<string>(url);
    };

    getApplicationsByServerName = async (name: string): Promise<IApplication[]> => {
        const url = `Servers/${name}/applications`;
        const results = await this.get<IApplication[]>(url);
        return results;
    };

    getVirtualAppsByWebSiteName = async (name: string): Promise<IApplication[]> => {
        const url = `WebSites/${name}/virtualApps`;
        const results = await this.get<IApplication[]>(url);
        return results;
    };

    getVirtualAppsByAppPoolName = async (name: string): Promise<IApplication[]> => {
        const url = `apppool/${name}/virtualApps`;
        const results = await this.get<IApplication[]>(url);
        return results;
    };
}

const appManagerClient = new AppManagerClient();
export default appManagerClient;
